import { memo as Memo, useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';

//* HOCs
import withLanguageContext from 'context/consumerHOC/LanguageConsumer';
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Helpers
import { dividedLines } from 'helpers/_functions';
import { textNewSlideByYAnim } from 'helpers/_gsapFunctions';

//* Components
import AnimationWrap from 'components/common/AnimationWrap';
import CustomLink from 'components/common/CustomLink';
import Text from 'components/common/Text';

//* Style
import AboutForHomeStyle from './style';

const AboutForHome = Memo(({ title, subtitle, dangerouslyText, translate, winWidth }) => {
	//! Router
	const { asPath } = useRouter();

	//! Refs
	const items = useRef([]);

	//! Title Refs
	const titleContainerRef = useRef();
	const titleWordRefs = useRef([]);
	const titleRowRefs = useRef([]);

	//! Subtitle Ref
	const subtitleRef = useRef();

	//! Dangerously Ref
	const dangerouslyRef = useRef();

	//! Btn Ref
	const btnRef = useRef();

	//! State
	const [secObj, setSecObj] = useState();

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current]);
		}
	}, [items, winWidth]);

	const startAnimeArr = useCallback((x) => {
		//! Checkers
		const arr = [...titleRowRefs.current, subtitleRef.current, dangerouslyRef.current, btnRef.current];
		const filterArr = arr.filter((el) => typeof el !== 'undefined');

		if (x) {
			//!arr, stagger, delay, duration
			textNewSlideByYAnim(filterArr, 0.2, 0.6, 0.5);
		}
	}, []);

	//! Title Rows Animated --- Start ---
	const titleWordsStoreOpacity = useMemo(() => {
		const wordsArr = title.split(' ');

		return wordsArr.map((word, i) => {
			return (
				<Text
					key={i}
					ref={(ref) => (titleWordRefs.current[i] = ref)}
					className={`uppercase h1 blackRussian gilroyMedium`}
					text={word}
				/>
			);
		});
	}, [title]);

	let titleRowsArr = Object.values(dividedLines(titleWordRefs.current, titleContainerRef.current));

	const titleAnimatedRowsStore = useMemo(() => {
		return titleRowsArr.map((line, index) => {
			return (
				<div
					key={index}
					className={`overflowHidden`}>
					<span
						ref={(ref) => (titleRowRefs.current[index] = ref)}
						className={`opacityZero flex-wrapper`}>
						{line.map((item, key) => {
							return (
								<Text
									key={key}
									className={`uppercase h1 blackRussian gilroyMedium`}
									text={item.innerText}
								/>
							);
						})}
					</span>
				</div>
			);
		});
	}, [titleRowsArr, winWidth]);
	//! --- End ---

	return (
		<AnimationWrap
			state={secObj}
			startAnimeArr={startAnimeArr}
			dependency={asPath}>
			<AboutForHomeStyle ref={items}>
				<div
					ref={titleContainerRef}
					className={`lines-relative-wrapper homeAboutTitle`}>
					<div className={`animated-flex-wrapper`}>{titleAnimatedRowsStore}</div>

					<div className={`animated-flex-wrapper absolute-opacity-wrapper`}>{titleWordsStoreOpacity}</div>
				</div>

				<div className={`overflowHidden`}>
					<Text
						className={`h4 blackRussian maisonNeueBook homeAboutSubtitle opacityZero`}
						text={subtitle}
						ref={subtitleRef}
					/>
				</div>

				{dangerouslyText && (
					<div className={`textWrap overflowHidden`}>
						<div
							className={`dangerouslyWrap opacityZero`}
							dangerouslySetInnerHTML={{ __html: dangerouslyText }}
							ref={dangerouslyRef}
						/>
					</div>
				)}

				<div className={`btnWrap overflowHidden`}>
					<div
						className={`btnInternalWrap opacityZero noWrap`}
						ref={btnRef}>
						<CustomLink
							url={`/about-us/`}
							className={`uppercase h4 gilroySemiBold blackRussian btnLineAnim`}
							content={translate('aboutUs')}
						/>
					</div>
				</div>
			</AboutForHomeStyle>
		</AnimationWrap>
	);
});

export default withLanguageContext(withUIContext(AboutForHome, ['winWidth']), ['translate']);
