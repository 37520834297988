import { memo as Memo, useEffect, useRef } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Helper's
import { circleClipPathAnim } from 'helpers/_gsapFunctions'

//* Components
import Video from 'components/common/Video'
import Image from 'components/common/Image'

//* Style
import HomeHeroStyle from './style'

const HomeHero = Memo(({ file, preloader }) => {
	//! Ref
	const homeFileRef = useRef()

	useEffect(() => {
		//! Checkers
		const arr = [homeFileRef.current]
		const filterArr = arr.filter((el) => typeof el !== 'undefined')

		if (!preloader) {
			//! item, delay, duration
			circleClipPathAnim(...filterArr, 0.5, 0.7)
		}
	}, [preloader])

	return (
		<HomeHeroStyle>
			<div className={`homeHeroFileWrap circleClipPathDefaultState`} ref={homeFileRef}>
				{file.type === 'image' ? <Image priority src={file.url} alt={file.alt} /> : <Video src={file.url} autoplay={true} loop={true} />}
			</div>
		</HomeHeroStyle>
	)
})

export default withUIContext(HomeHero, ['preloader'])
