import styled from 'styled-components';

const AboutForHomeStyle = styled.section`
	--homeAboutTitleMaxWidth: 72.3%;
	--homeAboutTextMaxWidth: 49.2%;
	--textMTop: var(--sp5x);
	--distanceSize: var(--sp7x);
	--circSize: var(--sp2x);

	margin-top: var(--sectionsDistance);

	/* .homeAboutTitle {
		max-width: var(--homeAboutTitleMaxWidth);
	} */

	.homeAboutSubtitle {
		margin-top: var(--titleToTextDistance);
	}

	.textWrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-top: var(--textMTop);

		.dangerouslyWrap {
			max-width: var(--homeAboutTextMaxWidth);

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: var(--h5);
				font-family: gilroy;
				font-weight: 700;
				color: var(--blackRussian);
				margin-bottom: var(--distanceSize);

				&:not(:first-child) {
					margin-top: var(--distanceSize);
				}
			}

			h1,
			h2,
			h3 {
				line-height: var(--lineHeightS);
			}

			h4,
			h5,
			h6 {
				line-height: var(--lineHeightL);
			}

			p {
				font-size: var(--p);
				font-family: maisonNeue;
				font-weight: 500;
				color: var(--blackRussian);
				line-height: var(--lineHeightL);

				&:not(:first-child) {
					margin-top: var(--distanceSize);
				}

				&:not(:last-child) {
					margin-bottom: var(--distanceSize);
				}
			}

			a {
				font-family: maisonNeue;
				font-weight: 600;
				font-size: var(--p);
				line-height: var(--lineHeightL);
				color: var(--blackRussian);
				text-decoration: underline !important;

				&:hover {
					color: var(--blackRussian);
				}
			}

			ul,
			ol {
				li {
					font-size: var(--p);
					font-family: maisonNeue;
					font-weight: 500;
					color: var(--blackRussian);
					display: block;
					padding-left: 1.2em;
					text-indent: -1.2em;
					line-height: var(--lineHeightL);

					&:before {
						content: '';
						width: var(--circSize);
						height: var(--circSize);
						border-radius: 50%;
						background-color: var(--blackRussian);
						display: inline-block;
						margin-right: 2%;
					}

					&:not(:first-child) {
						margin-top: calc(var(--distanceSize) - var(--sp1x));
					}

					&:not(:last-child) {
						margin-bottom: calc(var(--distanceSize) - var(--sp1x));
					}
				}

				margin-bottom: var(--distanceSize);

				&:not(:first-child) {
					margin-top: var(--distanceSize);
				}
			}

			figure {
				margin-block-start: 0;
				margin-block-end: 0;
				margin-inline-start: 0;
				margin-inline-end: 0;
			}

			img {
				width: -webkit-fill-available;
				margin-bottom: var(--distanceSize);

				&:not(:first-child) {
					margin-top: var(--distanceSize);
				}
			}

			b,
			strong {
				font-family: gilroy;
				font-weight: 700;
				font-size: var(--p);
				color: var(--blackRussian);
				line-height: var(--lineHeightL);
			}

			i {
				font-family: gilroy;
				font-weight: 400;
				font-size: var(--p);
				color: var(--blackRussian);
				line-height: var(--lineHeightL);
			}

			ol {
				margin-block-start: 0;
				margin-block-end: 0;
				margin-inline-start: 0;
				margin-inline-end: 0;
				padding-inline-start: 0;
			}
		}
	}

	.btnWrap {
		display: flex;
		justify-content: flex-end;
		margin-top: var(--contentToSmallButtonDistance);
	}

	//! Line styles and animations
	.btnLineAnim {
		position: relative;

		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 85%;
			height: 2px;
			width: 100%;
			right: 0;
			background-color: var(--blackRussian);
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 85%;
			left: 0;
			height: 2px;
			background-color: var(--blackRussian);
		}

		&:after,
		&:before {
			padding-bottom: 1px;
		}

		&:hover {
			&:before {
				width: 0%;
				animation: lineAnim var(--trTimeS) ease-out reverse;
			}

			&:after {
				animation: lineAnim var(--trTimeS) var(--trTimeN) ease-out forwards;
			}
		}

		@keyframes lineAnim {
			0% {
				width: 0%;
			}

			100% {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--homeAboutTitleMaxWidth: 79.1%;
		--homeAboutTextMaxWidth: 48.9%;
		--textMTop: var(--sp4x);
		--distanceSize: var(--sp6x);
		--circSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--homeAboutTitleMaxWidth: 80.1%;
		--homeAboutTextMaxWidth: 49%;
		--textMTop: var(--sp3x);
		--distanceSize: var(--sp5x);
		--circSize: var(--sp1-5x);

		.lines-relative-wrapper {
			.h1 {
				font-size: var(--sp12x) !important;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--homeAboutTitleMaxWidth: 83.5%;
		--homeAboutTextMaxWidth: 49.1%;
		--textMTop: var(--sp3x);
		--distanceSize: var(--sp5x);
		--circSize: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--homeAboutTitleMaxWidth: 74.5%;
		--homeAboutTextMaxWidth: 49%;
		--textMTop: var(--sp3x);
		--distanceSize: var(--sp4x);
		--circSize: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--homeAboutTitleMaxWidth: 82.9%;
		--homeAboutTextMaxWidth: 48.8%;
		--textMTop: var(--sp3x);
		--distanceSize: var(--sp4x);
		--circSize: var(--sp1x);

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			//! Disable Line styles and animations
			.btnLineAnim {
				&:hover {
					&:before {
						width: 100%;
						animation: unset;
					}

					&:after {
						animation: unset;
					}
				}

				&:after,
				&:before {
					padding-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--homeAboutTitleMaxWidth: 100%;
		--homeAboutTextMaxWidth: 48.1%;
		--textMTop: var(--sp3x);
		--distanceSize: var(--sp3x);
		--circSize: var(--sp1x);

		.lines-relative-wrapper {
			.h1 {
				font-size: var(--sp7x) !important;
			}
		}

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--homeAboutTitleMaxWidth: 100%;
		--homeAboutTextMaxWidth: 100%;
		--textMTop: var(--sp2x);
		--distanceSize: var(--sp2x);
		--circSize: var(--sp1x);

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}
`;

export default AboutForHomeStyle;
