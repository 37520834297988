import styled from 'styled-components'

const HomeHeroStyle = styled.section`
	--homeFileHeight: 47.3%;

	.homeHeroFileWrap {
		.videoCont,
		.imageCont {
			--proportion: var(--homeFileHeight);
			width: 100%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeL}) {
		--homeFileHeight: 46.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--homeFileHeight: 53.1%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--homeFileHeight: 47.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--homeFileHeight: 53.2%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--homeFileHeight: 65.7%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeS}) {
		--homeFileHeight: 89.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--homeFileHeight: 181.7%;
	}
`

export default HomeHeroStyle
