import { memo as Memo, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useRouter } from 'next/router'

//* HOC
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helper
import { textNewSlideByYAnim } from 'helpers/_gsapFunctions'

//* Components
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'
import FilesSection from 'components/common/FilesSection'
import AnimationWrap from 'components/common/AnimationWrap'
import TextForPage from 'components/common/TextForPageSection'

//* Style
import ProjectsForHomeStyle from './style'

const ProjectsForHome = Memo(({ projectRows, className, translate }) => {
	//! Router
	const { asPath } = useRouter()

	//! State
	const [secObj, setSecObj] = useState()

	//! Refs
	const items = useRef([])
	const homeProjectsTitleRef = useRef()
	const homeBtnRef = useRef()

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current])
		}
	}, [items])

	const startAnimeArr = useCallback((x) => {
		//! Checkers
		const arr = [homeProjectsTitleRef.current, homeBtnRef.current]
		const filterArr = arr.filter((el) => typeof el !== 'undefined')

		if (x) {
			//! item, stagger, delay, duration
			textNewSlideByYAnim(filterArr, 0.05, 0.7, 0.5)
		}
	}, [])

	//! Projects title
	const projectsTitle = useMemo(() => {
		return (
			<Text
				tag={`h1`}
				className={`h1 gilroyMedium blackRussian uppercase homeProjectsTitle opacityZero`}
				text={`works`}
				ref={homeProjectsTitleRef}
			/>
		)
	}, [])

	return (
		<AnimationWrap state={secObj} startAnimeArr={startAnimeArr} dependency={asPath}>
			<ProjectsForHomeStyle className={`${className || ''}`} ref={items}>
				<div className={`overflowHidden`}>{projectsTitle}</div>

				<div className={`homeProjectsGlobalWrapper`}>
					{projectRows.map((projectRow, index) => {
						switch (projectRow.type) {
							case 'text':
								return <TextForPage key={index} long={projectRow.text.long} short={projectRow.text.short} />

							default:
								return (
									<FilesSection
										key={index}
										left_project={projectRow.left_project}
										right_project={projectRow.right_project}
										typeAndClass={projectRow.type}
									/>
								)
						}
					})}
				</div>

				<div className={`btnWrap overflowHidden`}>
					<div className={`btnInternalWrap opacityZero`} ref={homeBtnRef}>
						<CustomLink
							url={`/works/`}
							className={`uppercase h2 gilroyMedium blackRussian btnLineAnim`}
							content={translate('allWorks')}
						/>
					</div>
				</div>
			</ProjectsForHomeStyle>
		</AnimationWrap>
	)
})

export default withLanguageContext(ProjectsForHome, ['translate'])
