import styled from 'styled-components'

const ProjectsForHomeStyle = styled.section`
	.homeProjectsTitle {
		margin-top: var(--sectionsDistance);
		margin-bottom: var(--picToPicGalleryDistance);
	}

	.homeProjectsGlobalWrapper {
		.fileSectionWrap {
			margin-top: var(--sectionsDistance);
			margin-bottom: var(--sectionsDistance);

			&:nth-child(1) {
				margin-top: var(--picToPicGalleryDistance);
			}
		}
	}

	.btnWrap {
		display: flex;
		justify-content: center;
		margin-top: var(--sectionsDistance);
	}

	//! Line styles and animations
	.btnLineAnim {
		position: relative;

		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 85%;
			height: 2px;
			width: 100%;
			right: 0;
			background-color: var(--blackRussian);
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 85%;
			left: 0;
			height: 2px;
			background-color: var(--blackRussian);
		}

		&:after,
		&:before {
			padding-bottom: 1px;
		}

		&:hover {
			&:before {
				width: 0%;
				animation: lineAnim var(--trTimeS) ease-out reverse;
			}

			&:after {
				animation: lineAnim var(--trTimeS) var(--trTimeN) ease-out forwards;
			}
		}

		@keyframes lineAnim {
			0% {
				width: 0%;
			}

			100% {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}
`

export default ProjectsForHomeStyle
