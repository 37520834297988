import { memo as Memo, useMemo } from 'react'

//* HOC
import withDataContext from 'context/consumerHOC/DataConsumer'

//* Components
import Page from 'components/common/Page'
import Container from 'components/common/Container'
import HomeHero from 'components/pages/Home/HeroSection'
import AboutForHome from 'components/pages/Home/AboutForHomeSection'
import ProjectsForHome from 'components/pages/Home/ProjectsForHomeSection'

const Home = Memo(({ pages, pageParams, selectedLang }) => {
	//! Page Data
	const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams])

	return (
		<Page className='home' meta={pageData?.content?.meta}>
			{pageData && (
				<Container>
					<div>
						<HomeHero file={pageData.content.file} />

						<AboutForHome title={pageData.content.title} subtitle={pageData.content.subtitle} dangerouslyText={pageData.content.text} />

						{pageData.projectRows?.length > 0 ? <ProjectsForHome projectRows={pageData.projectRows} /> : null}
					</div>
				</Container>
			)}
		</Page>
	)
})

export default withDataContext(Home, ['pages'])
