import { memo as Memo, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Helpers
import { dividedLines } from 'helpers/_functions';
import { textNewSlideByYAnim } from 'helpers/_gsapFunctions';

//* Components
import AnimationWrap from 'components/common/AnimationWrap';
import Text from 'components/common/Text';

//* Style
import TextForPageStyle from './style';

const TextForPage = Memo(({ className, long, short, winWidth }) => {
	//! Router
	const { asPath } = useRouter();

	//! Refs
	const items = useRef([]);

	//! Long Text Refs
	const longTextWordRefs = useRef([]);
	const longTextRowRefs = useRef([]);

	//! Short text Ref
	const shortRef = useRef();

	//! State
	const [secObj, setSecObj] = useState();

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current]);
		}
	}, [items, winWidth]);

	const startAnimeArr = useCallback((x) => {
		//! Checkers
		const arr = [...longTextRowRefs.current, shortRef.current];
		const filterArr = arr.filter((el) => typeof el !== 'undefined');

		if (x) {
			//! item, stagger, delay, duration
			textNewSlideByYAnim(filterArr, 0.2, 0.4, 0.5);
		}
	}, []);

	//! Long Text Rows Animated --- Start ---
	const longTextWordsStoreOpacity = useMemo(() => {
		const wordsArr = long.split(' ');

		return wordsArr.map((word, i) => {
			return (
				<Text
					key={i}
					ref={(ref) => (longTextWordRefs.current[i] = ref)}
					className={`uppercase h2 blackRussian gilroyMedium`}
					text={word}
				/>
			);
		});
	}, [long]);

	let longTextRowsArr = Object.values(dividedLines(longTextWordRefs.current, items.current));

	const longTextAnimatedRowsStore = useMemo(() => {
		return longTextRowsArr.map((line, index) => {
			return (
				<div
					key={index}
					className={`overflowHidden`}>
					<div
						ref={(ref) => (longTextRowRefs.current[index] = ref)}
						className={`opacityZero flex-wrapper`}>
						{line.map((item, key) => {
							return (
								<Text
									key={key}
									className={`uppercase h2 blackRussian gilroyMedium`}
									text={item.innerText}
								/>
							);
						})}
					</div>
				</div>
			);
		});
	}, [longTextRowsArr, winWidth]);
	//! --- End ---

	return (
		<AnimationWrap
			state={secObj}
			startAnimeArr={startAnimeArr}
			dependency={asPath}>
			<TextForPageStyle
				ref={items}
				className={`textProjectWrap ${className || ''}`}>
				<div className={`lines-relative-wrapper`}>
					<div className={`animated-flex-wrapper`}>{longTextAnimatedRowsStore}</div>

					<div className={`animated-flex-wrapper absolute-opacity-wrapper`}>{longTextWordsStoreOpacity}</div>
				</div>

				<div className={`overflowHidden short-text-wrapper`}>
					<Text
						className={`h5 blackRussian maisonNeueBook opacityZero`}
						text={short}
						ref={shortRef}
					/>
				</div>
			</TextForPageStyle>
		</AnimationWrap>
	);
});

export default withUIContext(TextForPage, ['winWidth']);
